import React from 'react'
import BarraAcessibilidade from '../BarraAcessibilidade'
import OwlCarousel from 'react-owl-carousel2'
import Footer from '../Footer'
import events from '../../script/DOMController'
import {Link} from 'react-router-dom'
import Hosts from '../../global/Hosts'
import Idioma from '../idiomaComponent/idioma'
import LacoHeader from '../LacoHeaderComponent/LacoHeader'
export default class Projecao extends React.Component{

    options = {
        loop:true,
        margin:25,
        nav:true,
        dots:false,
        center: true,
        navText: ["<img src='assets/img/btLeft.png' alt='botão anterior lista'>","<img src='assets/img/btRight.png' alt='botão próximo lista'>"],
        responsive:{
          0:{
            items:1
          },
          600:{
              items:1
          },
          1000:{
              items:1
          }
        }
    }

    componentDidMount(){
        events.docReady()                
    }
    render(){
        return(
            <div className="gamesdemo">
                <div className="topo" id="inicio">
                    <BarraAcessibilidade/>
                </div>
        
                <div id="main" className="fase1">
                    
                    <div className="interno">
                        <section class="header">
                            <LacoHeader selecao="en" pagina="projecao" />
                        </section>
            
                        <div className="conteudo">
            
                            <div className="txtProjecao">
                                <div className="container">
                                    <table className="tbDestaque">
                                        <tr>
                                            <td className="txt"><h1>Demo</h1></td>
                                            <td className="imagem"><img src="assets/img/webby-interno.png" alt="Digital Pack"/></td>
                                        </tr>
                                    </table>
                                    <h3>DIGITAL BOOK (IWB)</h3>
                                    <p>An exclusive feature for the teacher to be used on interactive whiteboards and projectors. It has the same format as the printed book and provides the audios, the other digital objects in the collection and the answers to the activities, as well as marking tools.</p>
                                </div>
                            </div>
            
                            <div className="container imagens">
            
                                <div className="row carouselInterno">
                                    <div className="col-xs-12 col-sm-12 col-md-12">
                    
                                        <OwlCarousel options={this.options}>
                                            <div className="item">
                                                <img className="img-fluid" src="assets/img/projecao/1.jpg" alt="Galeria 1"/>
                                            </div>
                                            <div className="item">
                                                <img className="img-fluid" src="assets/img/projecao/2.jpg" alt="Galeria 2"/>
                                            </div>
                                            <div className="item">
                                                <img className="img-fluid" src="assets/img/projecao/3.jpg" alt="Galeria 3"/>
                                            </div>
                                            <div className="item">
                                                <img className="img-fluid" src="assets/img/projecao/4.jpg" alt="Galeria 4"/>
                                            </div>
                                            <div className="item">
                                                <img className="img-fluid" src="assets/img/projecao/5.jpg" alt="Galeria 5"/>
                                            </div>
                                        </OwlCarousel>
                        
                                    </div>
                                </div>
            
                            </div>
            
                        </div>
            
                    </div>
            
                </div>
            
                <Footer seta={true} idioma="en"/>
        </div>    
        );
    }
}