import React from 'react';

function barraAcessibilidade(){
    return(
        <div className="barraAcessivel" role="menubar" aria-label="Barra de Acessibilidade">
            <div className="container">
                <div className="row">
                    <ul className="pull-left hidden-xs hidden-sm" aria-label="Navegação pela página">
                        <li role="menuitem"><a accessKey="1" href="#conteudo" title="Ir para o conteúdo"><span lang="pt-br">Ir para o conteúdo</span> <span>[1]</span></a></li>
                        <li role="menuitem"><a accessKey="2" href="#menuPrincipal" title="Ir para o menu"><span lang="pt-br">Ir para o menu</span> <span>[2]</span></a></li>
                        <li role="menuitem" className="closeContrast"><a accessKey="3" href="#txtBusca" title="Ir para a busca"><span lang="pt-br">Ir para a busca</span> <span>[3]</span></a></li>
                        <li role="menuitem" className="closeContrast"><a accessKey="4" href="#rodape" title="Ir para o rodapé"><span lang="pt-br">Ir para o rodapé</span> <span>[4]</span></a></li>
                    </ul>
                    <ul className="pull-right" aria-label="Controles de acessibilidade do site">
                        <li role="menuitem" className="closeContrast"><a href="https://www.richmond.com.br/acessibilidade-richmond/acessibilidade.htm" title="Acessar a página de acessibilidade"  target="_blank" className="pgacessibilidade"><span lang="pt-br"></span></a></li>
                        <li role="menuitem" className="livroAcessivel"><a href="https://www.richmond.com.br/acessibilidade-richmond/acessibilidade.htm" title="Link para Acessibilidade : Link externo" target="_blank" className="pgacessibilidade"><span lang="pt-br">Acessibilidade</span></a></li>
                        <li role="menuitem" className="livroAcessivel"><a href="https://www.moderna.com.br/acessibilidade-moderna/livro-acessivel/codigo/origem/" title="Link para Livro Acessível : Link externo" className="livroAcessivel"><span lang="pt-br"></span></a></li>
                        <li role="menuitem" className="zoomButtons"><a href="#" title="Ampliar tela" className="zoomIn gm5zoom"><span lang="pt-br">A+</span></a></li>
                        <li role="menuitem" className="zoomButtons closeContrast"><a href="#" title="Reduzir tela" className="zoomOut gm5zoom"><span lang="pt-br">A-</span></a></li>
                        <li role="menuitem" className="contraste">
                            <a href="javascript:void(0);" title="Contraste" className="contraste" aria-haspopup="true"><span className="oculto" lang="pt-br">Contraste</span></a>
                            <div className="dautoniveis closed" aria-label="submenu">
                                <ul>
                                    <li><a href="#" title="Preto, branco e amarelo" data-nivel="amarelopreto"><span lang="pt-br">Preto, branco e amarelo</span></a></li>
                                    <li><a href="#" title="Contraste aumentado" data-nivel="altocontraste"><span lang="pt-br">Contraste aumentado</span></a></li>
                                    <li><a href="#" title="Monocromático" data-nivel="monocromatico"><span lang="pt-br">Monocromático</span></a></li>
                                    <li><a href="#" title="Escala de cinza invertida" data-nivel="cinzainvertida"><span lang="pt-br">Escala de cinza invertida</span></a></li>
                                    <li><a href="#" title="Cores invertidas" data-nivel="corinvertida"><span lang="pt-br">Cores invertidas</span></a></li>
                                    <li><a href="#" title="Cores originais" data-nivel="normal"><span lang="pt-br">Cores originais</span></a></li>
                                </ul>
                            </div>
                        </li>
                        <li role="menuitem" className="lilibras"><a href="http://www.vlibras.gov.br" aria-label="Link para libras : Link externo" target="_blank" title="Link para libras : Link externo" className="pglibras"><span lang="pt-br">Libras</span></a></li>
                    </ul>
                    <div className="clearfix"></div>
                </div>
            </div>
        </div>

    );
}


export default barraAcessibilidade