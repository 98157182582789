import React from 'react'
import videojs from 'video.js'
import $ from 'jquery'
class ListaPlayer extends React.Component {
    
    constructor(){
        super();
        this.state={
            width:0,
            heigth:0,
            p1: null
        }
    }

    componentDidMount() {    
    
        this.player = videojs(this.videoNode, this.props.options, function onPlayerReady() {
            
        });
    
        this.setState({p1:this.player})
        
      if(this.props.options.language == 'pt'){
        $('.vjs-big-play-button').prop('title','Reproduzir vídeo');
      }

    }  
    componentDidUpdate(){
      
      if(this.props.options.language == 'pt'){
        $('.vjs-big-play-button').prop('title','Reproduzir vídeo');
      }
    } 
    render() {
        return(    
            <video  id='my-video' 
                    ref={ node => this.videoNode = node } 
                    className={this.props.videoClassName} 
                    width={this.props.options.width} 
                    height = {this.props.options.height}  
                    preload='auto'
                    poster={this.props.options.bg}
                    data-setup='{}'>               
            </video>            
        )
    }


  }
  


  export default ListaPlayer;