import React from 'react'
import { Link } from 'react-router-dom'
import BarraAcessibilidade from '../BarraAcessibilidade'
import OwlCarousel from 'react-owl-carousel2'
import events from '../../script/DOMController'
import Footer from '../Footer'
import Hosts from '../../global/Hosts'
import Idioma from '../idiomaComponent/idioma'
import LacoHeader from '../LacoHeaderComponent/LacoHeader'


class Games extends React.Component{

    options = {
        loop:true,
        margin:25,
        nav:true,
        dots:false,
        center: true,
        navText: ["<img src='"+Hosts['images']+"btLeft.png' alt='botão anterior lista'>","<img src='"+Hosts['images']+"btRight.png' alt='botão próximo lista'>"],
        responsive:{
          0:{
            items:1
          },
          600:{
              items:1
          },
          1000:{
              items:1
          }
        }
    }
    getImgSrc(imageName){
        return Hosts["images"]+imageName;
    }
    componentDidMount(){
        events.docReady()
    }
  
    render(){
        return(
            <div className="gamesdemo">

                <div className="topo" id="inicio">
                    <BarraAcessibilidade/>
                </div>

                <div id="main" className="fase1">
                    
                    <div className="interno">
                        <section class="header">
                            <LacoHeader selecao="pt" pagina="gamesdemo/en" />
                        </section>

                        {/* <div className="logo-interno">
                            <Link to={Hosts["localhost"]}><img className="img-fluid" src={this.getImgSrc("logo.png")} alt="Kids' Web"/></Link>
                        </div> */}

                        <div className="conteudo">

                            <div className="txtProjecao">
                                <div className="container">
                                    <table className="tbDestaque">
                                    <tbody>
                                        <tr>
                                            <td className="txt"><h1>Demo</h1></td>
                                            <td className="imagem"><img src={this.getImgSrc("webby-interno.png")} alt="Digital Pack"/></td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <h3>Kids' web games</h3>
                                    <p>Um aplicativo gratuito de jogos com diferentes tecnologias digitais, como realidade aumentada, ambientes em 360º e recurso anáglifo, que introduz o conteúdo dos volumes de forma interativa e lúdica, contribuindo para o processo de aprendizagem. Baixe e acesse o aplicativo, mire a capa de cada livro e desfrute desse recurso divertido e educativo.</p>
                                </div>
                            </div>

                            <div className="container imagens">

                                <div className="row carouselInterno">
                                    <div className="col-xs-12 col-sm-12 col-md-12">
                    
                                        <OwlCarousel options={this.options}>
                                            <div className="item">
                                                <img className="img-fluid" src={this.getImgSrc("games/app_galeria1.jpg")} alt="Galeria 1"/>
                                            </div>
                                            <div className="item">
                                                <img className="img-fluid" src={this.getImgSrc("games/app_galeria2.jpg")} alt="Galeria 2"/>
                                            </div>
                                            <div className="item">
                                                <img className="img-fluid" src={this.getImgSrc("games/app_galeria3.jpg")} alt="Galeria 3"/>
                                            </div>
                                            <div className="item">
                                                <img className="img-fluid" src={this.getImgSrc("games/app_galeria4.jpg")} alt="Galeria 4"/>
                                            </div>
                                            <div className="item">
                                                <img className="img-fluid" src={this.getImgSrc("games/app_galeria5.jpg")} alt="Galeria 5"/>
                                            </div>
                                        </OwlCarousel>
                        
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                <Footer seta={true} idioma="pt"/>
    
            </div>
    
        );
    }
}


export default Games;