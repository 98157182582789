import React from 'react'
import { HashRouter, Route, Switch, Redirect} from 'react-router-dom'
import Main from '../components/pt/Main'
import Projecao from '../components/pt/Projecao';
import ProjecaoEn from '../components/en/ProjecaoEn'
import isLogin from './route-guard'
import MainProfessor from '../components/pt/MainProfessor'
import MainProfessorEn from '../components/en/MainProfessorEn'
import naoautorizado from '../components/naoautorizado';
import Hosts from '../global/Hosts'
import Games from '../components/pt/Games'
import emocionais from '../components/pt/Emocionais';
import MainEs from '../components/en/Main-en'
import Clipe from '../components/pt/Clipe';
import ClipeEn from '../components/en/ClipeEn';
import EmocionaisEn from '../components/en/EmocionaisEn';
import GamesKw from '../components/pt/GamesKw';
import GamesKwEn from '../components/en/GamesKwEn';
import EmocionaisDemo from '../components/EmocionaisDemo';
import EmocionaisDemoEn from '../components/EmocionaisDemoEn';
import GamesDemonEN from '../components/en/GamesDemonEN'

const PrivateRoute = ({component: Component, ...rest})=>{
    return (
            <Route {...rest} render={props => (
                <Redirect to={Hosts["localhost"]}/>
        )} />
    );
}



const routes = ()=>{
    return(
        <HashRouter>
            <Switch>
                {/* Rotas abertas - PT */}
                <Route exact path = {Hosts["localhost"]} component = {Main}/>
                <Route exact path = {Hosts["localhost"]+'naoautorizado'} component = {naoautorizado}/>
                <Route exact path = {Hosts["localhost"]+'clipes'} component = {Clipe}/>                                                           
                <Route exact path={Hosts["localhost"]+'games'} component={GamesKw}/>
                <Route exact path={Hosts["localhost"]+'gamesdemo'} component={Games}/>                                            
                <Route exact path={Hosts["localhost"]+'projecao'} component={Projecao} />
                <Route exact path={Hosts['localhost']+'emocionais'} component = {emocionais}/>
                <Route exact path={Hosts['localhost']+'emocionaisdemo'} component = {EmocionaisDemo}/>
                {/* Rotas RESTRITAS - PT */}
                <PrivateRoute exact path = {Hosts["localhost"]+'area-do-professor'} component = {MainProfessor} /> 

                {/* Rotas abertas - EN */}
                <Route exact path = {Hosts["localhost"]+"en"} component = {MainEs}/>  
                <Route exact path={Hosts["localhost"]+'games/en'} component={GamesKwEn}/>
                <Route exact path = {Hosts["localhost"]+'clipes/en'} component = {ClipeEn}/>
                <Route exact path={Hosts["localhost"]+'projecao/en'} component={ProjecaoEn} />
                <Route exact path={Hosts["localhost"]+'emocionais/en'} component={EmocionaisEn} />
                <Route exact path={Hosts["localhost"]+'gamesdemo/en'} component={GamesDemonEN} />
                
                <Route exact path={Hosts['localhost']+'emocionaisdemo/en'} component = {EmocionaisDemoEn}/>
                <Route exact path = {Hosts["localhost"]+'naoautorizado/en'} component = {naoautorizado}/>     
                {/* Rotas RESTRITAS - EN */}
                <PrivateRoute exact path = {Hosts["localhost"]+'area-do-professor/en'} component = {MainProfessorEn} />                
            </Switch>
        </HashRouter>
    )
}
export default routes;