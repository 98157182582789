import React from 'react'
import Hosts from '../../global/Hosts'
export default class MenuButtonInfo extends React.Component{

    

    render(){

            return(<div className="container" id="inicio">
                    
                    <div id="clilinfo">
                        <div className="col-xs-12 col-sm-12 col-md-6" >
                            <h2>CLIL AND LIFE SKILLS</h2>
                            <p>
                                Ao final das unidades ímpares, a obra apresenta uma página dupla que traz conteúdo de <b>CLIL</b> - com trabalho interdisciplinar sempre alinhado com as diretrizes da BNCC - ou de <b><i>Life Skills</i></b>, cujo objetivo é fazer os alunos usarem o inglês para refletir sobre as situações relevantes da sua realidade e desenvolver competências socioemocionais e cognitivas.                                
                            </p>
                        </div>
            
                        <div className="col-xs-12 col-sm-12 col-md-6 LivroImg">
                            <img className="img-fluid" src={Hosts['images']+"img_clil.png"} alt="Clil and Life skills"/>
                        </div>
                    </div>
                
                    <div id="glossarioinfo" className="off">
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <h2>GLOSSÁRIO INTERATIVO</h2>
                            <p>
                                O tradicional glossário bilíngue passa a ser interativo nos volumes de 1 a 3. O aluno interage com o glossário de maneira lúdica, desenhando e completando imagens e palavras, tudo para construir seu próprio repertório.
                            </p>
                        </div>
            
                        <div className="col-xs-12 col-sm-12 col-md-6 LivroImg">
                            <img className="img-fluid" src={Hosts['images']+"glossario.png"} alt="Glossário Interativo"/>
                        </div>
                    </div>

                    <div id="bnccinfo" className="off">
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <h2>ALINHAMENTO COM A BNCC</h2>
                            <p>
                                A coleção <b><i>Kids' Web</i></b> 3ª edição foi inteiramente pensada e produzida a partir das competências e habilidades descritas na Base Nacional Comum Curricular (BNCC), desde seus pilares fundamentais até seções e atividades específicas dentro das unidades.
                            </p>
                        </div>
            
                        <div className="col-xs-12 col-sm-12 col-md-6 LivroImg">
                            <img className="img-fluid" src={Hosts['images']+"bncc.png"} alt="Alinhamento com a BNCC"/>
                        </div>
                    </div>

                    <div id="bookletinfo" className="off">
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <h2>BOOKLET PARA A FAMÍLIA</h2>
                            <p>
                                Impresso para os volumes 1 e 2, e digital para os demais volumes, o <i>booklet</i> para a família tem o objetivo de auxiliar pais e responsáveis no processo de aprendizagem de uma segunda língua.
                            </p>
                        </div>
            
                        <div className="col-xs-12 col-sm-12 col-md-6 LivroImg">
                            <img className="img-fluid" src={Hosts['images']+"family.png"} alt="Booklet para a família"/>
                        </div>
                    </div>
    
                </div>);
        
        
    }
}