import React from 'react'
import {Link} from 'react-router-dom'
import Hosts from '../global/Hosts'



export default class NavBar extends React.Component{
    redirecionarAreaProfessor=()=>{
        if(this.props.auth){
            return (<a href={'area-do-professor'} id='area_professor'title="Área do Professor">
            Área do <br/>Professor
            </a>)
        }else{
            return (<a href={Hosts['Login']} id='area_professor'title="Área do Professor">
                        Área do <br/>Professor
                    </a>);
        }
    }

    redirecionarAreaProfessorList=()=>{
        if(this.props.auth){
            return (<Link to={Hosts['localhost']+'area-do-professor/en'} id='area_professor'title="Área do Professor">
                        <li className="menuM">Área do Professor</li>
                    </Link>)
        }else{
            return (<a href={Hosts['Login']}><li className="menuM">Área do Professor</li></a>);
        }
    }
    
    render(){
    return(   <div className="row logo">
    <div className="laco">
        <div className="container">
            <div className="col-xs-12">
                <div className="idiomaDiv"> <a className="idioma">PT</a><span className="idioma separador">|</span><Link to={this.props.loader+'/en'} className="idioma">EN</Link></div>
            </div>
            <Link to={Hosts["localhost"]}><img className="img-fluid" src={Hosts['images']+"logo.png"} alt="Kids' Web"/></Link>
        </div>
    </div>
    <div className="container">

        <div className="col-xs-12 menu">

            <ul className="list-unstyled list-group list-group-horizontal nav">
                <li className="apresentacao linkMenu">
                    <a href="#apresentacao" title="Apresentação">
                        Apresentação
                    </a>
                </li>
                <li className="ipadOff linkMenu quebra">
                    <a href="#destaque" title="Destaques desta Edição">
                        Destaques<br/>Desta Edição
                    </a>
                </li>
                <li className="ipad linkMenu quebra">
                    <a href="#destaques" title="Destaques desta Edição">
                        Destaque<br/>Desta Edição
                    </a>
                </li>
                <li className="linkMenu">
                    <a href="#digitalpack" title="Digital Pack">
                        Digital Pack
                    </a>
                </li>
                <li className= "linkMenu quebra">
                    <a href="#posadocao" title="Teacher's Extra Resources">
                        Teacher's<br/>Extra Resources
                    </a>
                </li>
                <li className= "linkMenu quebra">
                    <a href="#universo" title="Universo Kids' Web">
                        Universo <br/>Kids' Web
                    </a>
                </li>
                <li className="linha">
                    <div></div>
                </li>
                <div className="dropdown">
                    <a className="dropbtn">Área do <br/>Aluno</a>
                    <div className="dropdown-content">
                        <a href={Hosts['pastimes']}>Kid's Web<br/>Pastimes</a>
                        <Link to={Hosts['localhost']+"emocionais"}>Animações<br/>Socioemocionais</Link>
                        <Link to={Hosts['localhost']+"clipes"}>Clipes Musicais</Link>
                        <Link to={Hosts['localhost']+"games"}>Kid's Web Games</Link>
                    </div>
                </div>
            </ul>

        </div>

    </div>
    {/* <!-- Hamburguer Menu --> */}
                <nav role="navigation">
                    <div id="menuToggle">
                        <input id="checkMenu" type="checkbox" />
                        <span class="hambMenu"></span>
                        <ul id="menu">
                            <a href="#apresentacao"><li id="menu1" class="menuM">Apresentação</li></a>
                            <a href="#destaque"><li id="menu2" class="menuM">Destaques desta edição</li></a>
                            <a href="#digitalpack"><li id="menu3" class="menuM">Digital pack</li></a>
                            <a href="#posadocao"><li id="menu4" class="menuM">Teacher's extra resources</li></a>
                            <a href="#universo"><li id="menu5" class="menuM">Universo kids' web</li></a>                            
                            <a href="#collapse1" data-toggle="collapse" aria-expanded="false" aria-controls="collapseExample"><li class="menuM">Área do aluno</li></a>
                            <div class="collapse" id="collapse1">
                                    <Link to={Hosts['localhost']}><li class="menuMCollapse">Kids' Web Pastimes</li></Link>
                                    <Link to={Hosts['localhost']+'emocionais'}><li class="menuMCollapse">Animações Socioemocionais</li></Link>
                                    <Link to={Hosts['localhost']+'clipes'}><li class="menuMCollapse">Clipes Musicais</li></Link>
                                    <Link to={Hosts['localhost']+'games'}><li class="menuMCollapse">Kids' Web Games</li></Link>
                            </div>
                        </ul>
                    </div>
                </nav>

    <div className="col-xs-12 menu-responsivo">
        <div className="container">
            
        </div>
        <div className="col-xs-12 col-sm-2 col-md-1 col-lg-1 col-lg-offset-2">
            <Link to={Hosts["localhost"]} className="noPadd"><img className="img-fluid" src={Hosts['images']+"logo.png"} alt="Kids' Web"/></Link>
        </div>

        <div className="col-xs-12 col-sm-10 col-md-11 col-lg-7">
            <ul className="list-unstyled list-group list-group-horizontal nav">
                <li className="apresentacao active">
                    <a href="#apresentacao" title="Apresentação">
                        Apresentação
                    </a>
                </li>
                <li className= "destaque quebra">
                    <a href="#destaque" title="Destaques desta Edição">
                        Destaques<br/>Desta Edição
                    </a>
                </li>
                <li className="ipad linkMenu quebra">
                    <a href="#destaques" title="Destaques desta Edição">
                        Destaque<br/>Desta Edição
                    </a>
                </li>
                <li>
                    <a href="#digitalpack" title="Digital Pack">
                        Digital Pack
                    </a>
                </li>
                <li className= "linkMenu quebra">
                    <a href="#posadocao" title="Teacher's Extra Resources">
                        Teacher's<br/>Extra Resources
                    </a>
                </li>
                <li className= "linkMenu quebra">
                    <a href="#universo" title="Universo Kids' Web">
                        Universo <br/>Kids' Web
                    </a>
                </li>
            </ul>
        </div>

    </div>

    <div className="col-sm-12 col-md-12 col-lg-12 imgHome">
        <img className="img-fluid kids" src={Hosts['images']+"home-ipad.png"} alt="Livros Kids' Web"/>
    </div>

    <div className="container">
        
        <div className="col-sm-12 col-md-12 col-lg-12 imgHomeMobile">
            <img className="img-fluid kids" src={Hosts['images']+"livro-mobile.png"} alt="Livros Kids' Web"/>
        </div>

        <div className="col-xs-12 col-sm-10 col-md-10 col-md-offset-1 col-sm-offset-1" id="apresentacao">
            {/* <!-- <div className="flex apresentação"> --> */}
            <div className="seta">
                <a href="#destaque" className="destaque"><i className="fas fa-angle-down"></i></a>
            </div>

            <h2>Aprendizado Divertido e significativo</h2>

            <p>A coleção <b><i>Kids' Web</i></b>, grande sucesso entre professores e alunos, ensina inglês de forma divertida e significativa. 
                A série estabelece uma forte ligação entre o aluno e o mundo da língua inglesa por meio da tecnologia, 
                além de oferecer uma gama de componentes digitais inovadores. 
                <b><i>Kids' Web</i></b> respeita o desenvolvimento da criança, sem interferir na alfabetização em sua língua materna, 
                e oferece diferentes formatos de atividades a cada etapa do crescimento.
            </p>

            <div className="seta2">
                <a href="#destaques" className="destaque"><i className="fas fa-angle-down"></i></a>
            </div>

            {/* <!-- </div> --> */}
        </div>

    </div>

    <div className="container">

        <div className="flex destaque">
            <div className="col-xs-12 col-sm-12 col-md-6 imgBoneco ipadOff" id="destaque">
                <img src={Hosts['images']+"personagens.png"} alt="Mia e Amigos"/>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-5 col-md-offset-1 col-lg-6 col-lg-offset-0 txtDestaque ipadOff">
                <h2>Destaques<br/> desta edição</h2>
            </div>
        </div>

    </div>

</div>
);
}
}

