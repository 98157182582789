import React from 'react'
import BarraAcessibilidade from '../BarraAcessibilidade'
import Hosts from '../../global/Hosts'
import {Link} from 'react-router-dom'
import Footer from '../Footer'
import events from '../../script/DOMController'
import axios from 'axios'
import {Redirect} from 'react-router-dom'
import { throwStatement } from '@babel/types'
import Utils from '../Utils'
import LacoHeader from '../LacoHeaderComponent/LacoHeader'
export default class MainProfessor extends React.Component{
    
    constructor(props){
        super(props);
        this.state={
            redirect:false,
            idAutorizado:Utils.getCookie('lumUserId')
        }
    }

    logout(){
        localStorage.clear()
        axios.get(Hosts['Logout']).finally(()=> window.location = Hosts['localhost']+'kidsweb')
    }

    componentDidMount(){
        events.docReady()

    }
    render(){
        return(
            <React.Fragment>
                <div className="topo" id="inicio">
                    <BarraAcessibilidade/>
                </div>
                <div className="mainProfessor" id='main'>
                <div class="interno">

                    <section className="header">
                        <LacoHeader selecao="pt" pagina="area-do-professor/en" professor={true} />                                               
                    </section>

                    <main className="areaDoProfessor" id='inicio'>
                    <div className="container">
                        {/* <!-- Área do Professor --> */}
                        <div className="row">
                            <div className="col-xs-10 col-xs-offset-1">
                                <div className="txtAreaDoProfessor">
                                    <h1>Área do Professor</h1>
                                    <p>Nesta área você encontra materiais para <i>download</i>, para que você possa armazená-los em seus dispositivos e usá-los <i>off-line</i>, projetando-os ou reproduzindo-os durante as aulas.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Flashcards --> */}
                        <div className="row">
                            <div className="col-xs-12">
                            <a href={Hosts['files']+"flashcards/Flashcards.zip"} title="Flashcards" download="flashcards">
                                <div className="flashcards">
                                    <h2>Flashcards</h2>
                                    <p>Um total de 148 <i>flashcards</i> apresenta os principais itens de vocabulário trabalhados na coleção.</p>
                                    <img className="img-responsive" src={Hosts["images"]+"/fotos_area-do-professor.png"} alt="Fotos de flashcards" />
                                </div>
                            </a>
                            </div>
                        </div>
                        {/* <!-- Pôsteres --> */}
                        <div className="row">
                            <div className="col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2">
                                <div className="posteres">
                                    <h2>Pôsteres</h2>
                                    <p>Os pôsteres (um para cada volume) retomam os conteúdos trabalhados durante o ano, com exceção do pôster do volume 1, que reforça a identificação do Webby e sua turma.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Volumes --> */}
                        <div className="row">
                            <div className="col-xs-12 col-md-10 col-md-offset-1">   
                                <div className="volumes desktop">
                                <a href={Hosts['files']+"posters/poster_3kw1_m.pdf"} title="Pôster – Volume 1" download="poster-volume-1">
                                    <img src={Hosts["images"]+"/volume1_desktop.png"} alt="Volume 1" />
                                </a>
                                <a href={Hosts['files']+"posters/poster_3kw2_m.pdf"} title="Pôster – Volume 2" download="poster-volume-2">
                                    <img src={Hosts["images"]+"/volume2_desktop.png"} alt="Volume 2" />
                                </a>
                                <a href={Hosts['files']+"posters/poster_3kw3_m.pdf"} title="Pôster – Volume 3" download="poster-volume-3">
                                    <img src={Hosts["images"]+"/volume3_desktop.png"} alt="Volume 3" />
                                </a>
                                <a href={Hosts['files']+"posters/poster_3kw4_m.pdf"} title="Pôster – Volume 4" download="poster-volume-4">
                                    <img src={Hosts["images"]+"/volume4_desktop.png"} alt="Volume 4" />
                                </a>
                                <a href={Hosts['files']+"posters/poster_3kw5_m.pdf"} title="Pôster – Volume 5" download="poster-volume-5">
                                    <img src={Hosts["images"]+"/volume5_desktop.png"} alt="Volume 5" />   
                                </a>                               
                                </div>
                                <div className="volumes mobile">
                                <a href={Hosts['files']+"posters/poster_3kw1_m.pdf"} title="Pôster – Volume 1" download="poster-volume-1">
                                    <img src={Hosts["images"]+"/volume1_mobile.png"} alt="Volume 1" />
                                </a>
                                <a href={Hosts['files']+"posters/poster_3kw2_m.pdf"} title="Pôster – Volume 2" download="poster-volume-2">
                                    <img src={Hosts["images"]+"/volume2_mobile.png"} alt="Volume 2" />
                                </a>
                                <a href={Hosts['files']+"posters/poster_3kw3_m.pdf"} title="Pôster – Volume 3" download="poster-volume-3">
                                    <img src={Hosts["images"]+"/volume3_mobile.png"} alt="Volume 3" />
                                </a>
                                <a href={Hosts['files']+"posters/poster_3kw4_m.pdf"} title="Pôster – Volume 4" download="poster-volume-4">
                                    <img src={Hosts["images"]+"/volume4_mobile.png"} alt="Volume 4" />
                                </a>
                                <a href={Hosts['files']+"posters/poster_3kw5_m.pdf"} title="Pôster – Volume 5" download="poster-volume-5">                                
                                    <img src={Hosts["images"]+"/volume5_mobile.png"} alt="Volume 5" />
                                </a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Livro Digital para Projeção --> */}
                        <div className="row">
                            <div className="col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2">
                                <div className="livroDigital">
                                    <h2>Livro digital para projeção</h2>
                                    <p>Oferece os áudios integrados e tem o mesmo formato do livro impresso para ser projetado em lousas interativas ou por meio de projetores.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Volume Livros --> */}
                        <div className="row">
                            <div className="col-xs-12 col-md-10 col-md-offset-1">   
                                <div className="livrosVolumes desktop">
                                <a href={Hosts['files']+"livroprojecao/volume1.zip"} title="Livro Digital para Projeção – Volume 1" download="livro-volume-1">
                                    <img src={Hosts["images"]+"/livro_vol1.png"} alt="Livro Digital para Projeção – Volume 1" />
                                </a>
                                <a href={Hosts['files']+"livroprojecao/volume2.zip"} title="Livro Digital para Projeção – Volume 2" download="livro-volume-2">
                                    <img src={Hosts["images"]+"/livro_vol2.png"} alt="Livro Digital para Projeção – Volume 2" />
                                </a>
                                <a href={Hosts['files']+"livroprojecao/volume3.zip"} title="Livro Digital para Projeção – Volume 3" download="livro-volume-3">
                                    <img src={Hosts["images"]+"/livro_vol3.png"} alt="Livro Digital para Projeção – Volume 3" />
                                </a>
                                <a href={Hosts['files']+"livroprojecao/volume4.zip"} title="Livro Digital para Projeção – Volume 4" download="livro-volume-4">
                                    <img src={Hosts["images"]+"/livro_vol4.png"} alt="Livro Digital para Projeção – Volume 4" />
                                </a>
                                <a href={Hosts['files']+"livroprojecao/volume5.zip"} title="Livro Digital para Projeção – Volume 5" download="livro-volume-5">
                                    <img src={Hosts["images"]+"/livro_vol5.png"} alt="Livro Digital para Projeção – Volume 5" />
                                </a>
                                </div>
                                <div className="livrosVolumes mobile">
                                <a href={Hosts['files']+"livroprojecao/volume1.zip"} title="Livro Digital para Projeção – Volume 1" download="livro-volume-1">
                                    <img src={Hosts["images"]+"/livro_vol1_mobile.png"} alt="Livro Digital para Projeção – Volume 1" />
                                </a>
                                <a href={Hosts['files']+"livroprojecao/volume2.zip"} title="Livro Digital para Projeção – Volume 2" download="livro-volume-2">
                                    <img src={Hosts["images"]+"/livro_vol2_mobile.png"} alt="Livro Digital para Projeção – Volume 2" />
                                </a>
                                <a href={Hosts['files']+"livroprojecao/volume3.zip"} title="Livro Digital para Projeção – Volume 3" download="livro-volume-3">
                                    <img src={Hosts["images"]+"/livro_vol3_mobile.png"} alt="Livro Digital para Projeção – Volume 3" />
                                </a>
                                <a href={Hosts['files']+"livroprojecao/volume4.zip"} title="Livro Digital para Projeção – Volume 4" download="livro-volume-4">
                                    <img src={Hosts["images"]+"/livro_vol4_mobile.png"} alt="Livro Digital para Projeção – Volume 4" />
                                </a>
                                <a href={Hosts['files']+"livroprojecao/volume1.zip"} title="Livro Digital para Projeção – Volume 5" download="livro-volume-5">
                                    <img src={Hosts["images"]+"/livro_vol5_mobile.png"} alt="Livro Digital para Projeção – Volume 5" />
                                </a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Áudio --> */}
                        <div className="row">
                            <div className="col-xs-12 col-md-8 col-md-offset-2">
                                <div className="audio">
                                    <h2>Áudios</h2>
                                    <p>Reúne os áudios e músicas das atividades de todos os volumes da coleção.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Volume Áudios --> */}
                        <div className="row">
                            <div className="col-xs-12 col-md-10 col-md-offset-1">   
                                <div className="audioVolumes desktop">
                                <a href={Hosts['files']+"audios/Volume1.zip"} title="Áudios – Volume 1" download="audio-volume-1">
                                    <img src={Hosts["images"]+"/audio_vol1.png"} alt="Áudios – Volume 1" />
                                </a>
                                <a href={Hosts['files']+"audios/Volume2.zip"} title="Áudios – Volume 2" download="audio-volume-2">
                                    <img src={Hosts["images"]+"/audio_vol2.png"} alt="Áudios – Volume 2" />
                                </a>
                                <a href={Hosts['files']+"audios/Volume3.zip"} title="Áudios – Volume 3" download="audio-volume-3">
                                    <img src={Hosts["images"]+"/audio_vol3.png"} alt="Áudios – Volume 3" />
                                </a>
                                <a href={Hosts['files']+"audios/Volume4.zip"} title="Áudios – Volume 4" download="audio-volume-4">
                                    <img src={Hosts["images"]+"/audio_vol4.png"} alt="Áudios – Volume 4" />
                                </a>
                                <a href={Hosts['files']+"audios/Volume5.zip"} title="Áudios – Volume 5" download="audio-volume-5">
                                    <img src={Hosts["images"]+"/audio_vol5.png"} alt="Áudios – Volume 5" />
                                </a>
                                </div>
                                <div className="audioVolumes mobile">
                                <a href={Hosts['files']+"audios/Volume1.zip"} title="Áudios – Volume 1" download="audio-volume-1">
                                    <img src={Hosts["images"]+"/audio_vol1_mobile.png"} alt="Áudios – Volume 1" />
                                </a>
                                <a href={Hosts['files']+"audios/Volume2.zip"} title="Áudios – Volume 2" download="audio-volume-2">
                                    <img src={Hosts["images"]+"/audio_vol2_mobile.png"} alt="Áudios – Volume 2" />
                                </a>
                                <a href={Hosts['files']+"audios/Volume3.zip"} title="Áudios – Volume 3" download="audio-volume-3">
                                    <img src={Hosts["images"]+"/audio_vol3_mobile.png"} alt="Áudios – Volume 3" />
                                </a>
                                <a href={Hosts['files']+"audios/Volume4.zip"} title="Áudios – Volume 4" download="audio-volume-4">
                                    <img src={Hosts["images"]+"/audio_vol4_mobile.png"} alt="Áudios – Volume 4" />
                                </a>
                                <a href={Hosts['files']+"audios/Volume5.zip"} title="Áudios – Volume 5" download="audio-volume-5">
                                    <img src={Hosts["images"]+"/audio_vol5_mobile.png"} alt="Áudios – Volume 5" />
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                </div>
                    <Footer seta={true} idioma="pt"/>

                </div>
            </React.Fragment>
        )
    }
}