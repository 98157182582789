const prod ={
        Login:'https://www.richmond.com.br/CustomServices/Moderna/Corporativo/Usuario/jsp/GerenciaJson.jsp?Method=RedirecionaComCookie&CodOrigemCadastro=1&UrlOrigem=https://www.kidsweb.com.br/area-do-professor/index.html&UrlDestino=https://www.richmond.com.br/login-richmond/login.htm',        
        LoginEn:'https://www.richmond.com.br/CustomServices/Moderna/Corporativo/Usuario/jsp/GerenciaJson.jsp?Method=RedirecionaComCookie&CodOrigemCadastro=1&UrlOrigem=https://www.kidsweb.com.br/area-do-professor/index.html?lang=en&UrlDestino=https://www.richmond.com.br/login-richmond/login.htm',        
        Logout:'https://kidsweb.com.br/logout/index.html',
        css:'/assets/css/',
        images:'/assets/img/',
        videos:'/assets/videos/',
        localImages: '/assets/img/',
        localFiles: '/assets/arquivos/',
        files:'/assets/arquivos/',
        areaDoProfessor: 'https://kidsweb.com.br/#/area-do-professor',
        areaDoProfessorEn: 'https://kidsweb.com.br/#/area-do-professor/en',
        pastimesdemo:"https://kidsweb.com.br/kwpastimes_demo_mkt/index_alu.html",
        pastimes:"https://kidsweb.com.br/pastimes/index.html",
        kidsdemo:"https://kidsweb.com.br/kidsweb_demo/index_alu.html",
        authApi:'http://www.richmond.com.br/CustomServices/Moderna/Kids/Login/jsp/GerenciaJson.jsp',
        localhost:'/',
        minhaContaRichmond:'https://www.richmond.com.br/CustomServices/Moderna/Corporativo/Usuario/jsp/GerenciaJson.jsp?Method=RedirecionaComCookie&CodOrigemCadastro=1&UrlOrigem=http://www.kidsweb.com.br/area-do-professor&UrlDestino=/minha-conta/',
        homeNotAllowed:"https://kidsweb.com.br/"
}
//ANTES DO DEPLOY, MUDAR A CONSTANTE DE EXPORTAÇÃO PARA O COMPONENTE DESEJADO
export default prod;
