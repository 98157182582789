import React, { Component } from 'react';
import Hosts from '../../global/Hosts';
import events from '../../script/DOMController';
import {Link} from 'react-router-dom';
import BarraAcessibilidade from '../BarraAcessibilidade';
import Idioma from '../idiomaComponent/idioma';
import LacoHeader from '../LacoHeaderComponent/LacoHeader';
import Footer from '../Footer';

export default class GamesKwEn extends Component {

    constructor(props) {
        super(props);

        this.state = {
            resize: document.documentElement.clientWidth >= 1024 ? 1024 : document.documentElement.clientWidth >= 768 && document.documentElement.clientWidth < 1024 ? 768 : 640  
        };

        this.handleResize = this.handleResize.bind(this);
    }

    handleResize(){
        console.log('alterando janela')
        if (document.documentElement.clientWidth >= 1024) {
            
            this.setState({
                resize: 1024
            });
        } else if(document.documentElement.clientWidth >= 768 && document.documentElement.clientWidth < 1024) {
            this.setState({
                resize: 768
            });
        }else{
            this.setState({
                resize: 640
            });
        }
    }

    componentDidMount(){
        events.docReady()
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        console.log('alterando janela'+' destroy')
        document.removeEventListener('resize', this.handleResize);
    }

    render() {
        return(
            <div>
            <div className="topo" id="inicio">
                    <BarraAcessibilidade />
                </div>
                <div id="main">
                    <div className="interno" >

                        {/* <!-- Logo --> */}
                        <section className="header">
                            <LacoHeader selecao="en" pagina="games"  />
                        </section>

                        <main className="games">
                            {/* <!-- Kids' Web Games --> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-10 col-xs-offset-1">
                                        <div className="kidsWebGames">
                                            <h1>KIDS' WEB GAMES</h1>
                                            <p>Free app for the 3rd edition of the <b><i>Kids' Web</i></b> series, aimed at the initial years of Elementary School.</p>
                                            <p>With augmented-reality games, 360-degree environments and an anaglyphic feature, the app helps you learn English from a variety of digital experiences! Follow the steps below to play:</p>
                                            <p>1. Go to the app store on your device and search for "<i>Kids' Web Games</i>" (or press the corresponding icon below).</p>
                                            <p>2. Download and install the app.</p>
                                            <p>3. Open the app and then press "Scan the cover".</p>
                                            <p>The app will access your device's camera. Just point the camera at the cover of each book to discover the digital experience that is waiting for you.  Fun and learning inside and outside the classroom are guaranteed!</p>
                                            <ul>
                                                <li>
                                                    <a href="https://apps.apple.com/br/app/kids-web-games/id1473487558" title="Download the app for iOS devices">
                                                        <img src={Hosts['images']+"download-ios.png"} alt="Download the app for iOS devices"/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://play.google.com/store/apps/details?id=br.com.richmond.kidsweb" title="Download the app for Android devices">
                                                        <img src={Hosts['images']+"download-android.png"} alt="Download the app for Android devices"/>
                                                    </a>
                                                </li>    
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Little Finder --> */}
                            <div className="kwBox branco">
                                <div className="container">
                                    <div className="row">
                                        
                                            <div className="txt col-xs-12 col-sm-5 col-md-5 col-md-offset-1">
                                                <h3 className="little">Little Finder (<i>Kids' Web</i>&nbsp;&nbsp;1)</h3>
                                                <p>An augmented-reality game in which the illustrated world on the cover of the book comes to life and new elements emerge within it.</p>
                                                <p>Your challenge will be to find the indicated elements. To do this, rotate the image and explore every corner of the world of Webby and his gang.</p>
                                                <p>Embark on a digital experience in the fascinating world of <b><i>Kids' Web</i></b>!</p>
                                            </div>
                                            <div className="images littleBox col-xs-12 col-sm-7 col-md-6">
                                                { this.state.resize === 1024 && <img className="little img1024" src={Hosts['images']+"banner-LITTLE-FINDER.png"} alt="LITTLE FINDER"/>}
                                                { this.state.resize === 768 &&  <img className="little img768" src={Hosts['images']+"little-finder-768-cortado.png"} alt="LITTLE FINDER"/>}
                                                { this.state.resize === 640 &&  <img className="little img640" src={Hosts['images']+"little-finder-640.png"} alt="LITTLE FINDER"/>}
                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Magic Animals --> */}
                            <div className="kwBox">
                                <div className="container">
                                    <div className="row">
                                        
                                            <div className="txt magicAnimalsBox col-xs-12 col-sm-6 col-md-7">
                                                <h3 className="col-xs-12 col-md-10">Magic Animals (<i>Kids' Web</i>&nbsp;&nbsp;2)</h3>
                                                <p className="col-xs-12 col-md-10">An anaglyph game in which the giraffe illustrated on the cover of the book takes you on a wild adventure.</p>
                                                <p className="col-xs-12 col-md-10">Your challenge will be to count the animals of the indicated species using the glasses that come with your book. Close one eye at a time to find out which lens you can see them with (the blue or red one).</p>
                                                <p className="col-xs-12 col-md-10">Keep an eye on the timer and see if you counted the animals correctly!</p>
                                                <p className="col-xs-12 col-md-10">This digital and animal experience will be an adventure!</p>
                                            </div>
                                            <div className="images magicAnimalsBox col-xs-12 col-sm-6 col-md-5">
                                                <img className="magicAnimals" src={Hosts['images']+"banner-MAGIC-ANIMALS.png"} alt="MAGIC ANIMALS"/>
                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Home Sweet Home --> */}
                            <div className="kwBox branco">
                                <div className="container">
                                    <div className="row">
                                        
                                            <div className="txt col-xs-12 col-sm-5 col-md-5 col-md-offset-1">
                                                <h3>Home Sweet Home (<i>Kids' Web</i>&nbsp;&nbsp;3)</h3>
                                                <p>A 360-degree game that will transport you to the house of the girl sitting at the desk illustrated on the cover of the book. In this house there are some problems that can disturb the harmony.</p>
                                                <p>Your challenge will be to explore each room by moving your device to find the mess and tidy it up.</p>
                                                <p>Feel at home in this house and leave it tidy, thus experiencing a digital and investigative experience!</p>
                                            </div>
                                            <div className="images col-xs-12 col-sm-6">
                                                { this.state.resize === 1024 && <img className="sweetHome img1024" src={Hosts['images']+"banner-HOME-SWEET-HOME.png"} alt="HOE SWEET HOME"/>}
                                                { this.state.resize === 768 &&  <img className="sweetHome img768" src={Hosts['images']+"home-768.png"} alt="HOME SWEET HOME"/>}
                                                { this.state.resize === 640 &&  <img className="sweetHome img640" src={Hosts['images']+"home-640.png"} alt="HOME SWEET HOME"/>}
                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                            {/* <!-- The Egg's Journey --> */}
                            <div className="kwBox eggsJourneyBox">
                                <div className="container">
                                    <div className="row">
                                        
                                            <div className="txt txtEggsJourneyBox col-xs-12 col-sm-6 col-md-7">
                                                <h3 className="col-xs-12 col-md-10">The Egg's Journey (<i>Kids' Web</i>&nbsp;&nbsp;4)</h3>
                                                <p className="col-xs-12 col-md-10">A 360-degree game in which you will get to know the story of the egg illustrated on the cover of the book. It fell from the chicken coop and needs to find its mother.</p>
                                                <p className="col-xs-12 col-md-10">Your challenge is to accompany the egg by moving your device to help it overcome obstacles along the journey in search of its mother. Will it be able to find her?</p>
                                                <p className="col-xs-12 col-md-10">Embark on this journey in search of the mother hen and the loving family that are waiting for the lost egg!</p>
                                            </div>
                                            <div className="images imageEggsJourneyBox  col-xs-12 col-sm-6 col-md-5">
                                                { this.state.resize > 640 && <img className="eggsJourney img1024" src={Hosts['images']+"banner-THE-EGGS-JOURNEY.png"} alt="THE EGGS JOURNEY"/>}
                                                { this.state.resize <= 640 && <img className="eggsJourney img640" src={Hosts['images']+"eggs-journey-640.png"} alt="THE EGGS JOURNEY"/>}
                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                            {/* <!-- World Explorer --> */}
                            <div className="kwBox branco worldExplorerBox">
                                <div className="container">
                                    <div className="row">
                                        
                                            <div className="txt col-xs-12 col-sm-5 col-md-5 col-md-offset-1">
                                                <h3>World Explorer (<i>Kids' Web</i>&nbsp;&nbsp;5)</h3>
                                                <p>An augmented-reality game in which the world illustrated on the cover of the book turns into a gamified globe.</p>
                                                <p>You will hear a sequence of countries' names, which gets longer as the game goes on.</p>
                                                <p>Your challenge will be to locate the countries on the globe according to the same sequence presented. With each correct sequence, you will learn more about one of the countries.</p>
                                                <p>Embark on this journey, test your memory and enjoy a digital and exploring experience!</p>
                                            </div>
                                            <div className="images col-xs-12 col-sm-6">
                                                { this.state.resize === 1024 && <img className="worldExplorer img1024" src={Hosts['images']+"banner-WORLD-EXPLORER.png"} alt="WORLD EXPLORER"/>}
                                                { this.state.resize === 768 && <img className="worldExplorer img768" src={Hosts['images']+"world-explorer-768.png"} alt="WORLD EXPLORER"/>}
                                                { this.state.resize === 640 && <img className="worldExplorer img640" src={Hosts['images']+"world-explorer-640.png"} alt="WORLD EXPLORER"/>}
                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Perguntas Frequentes --> */}
                            <div className="container">
                                <div className="perguntasFrequentes">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-10 col-md-offset-1">
                                            <h1>Frequently asked questions</h1>
                                            
                                            <div className="conteudoCollapse">
                                                <a data-toggle="collapse" href="#collapse1" aria-expanded="false" aria-controls="collapseExample">
                                                    1. On which devices can I download <i>Kids' Web Games</i>?
                                                </a>
                                                <div className="collapse pleft" id="collapse1">
                                                    <p>On tablets or smartphones with Android or iOS operating systems. There is no web or desktop version.</p>
                                                </div>
                                            </div>

                                            <div className="conteudoCollapse">
                                                <a data-toggle="collapse" href="#collapse2" aria-expanded="false" aria-controls="collapseExample">
                                                    2. What are the minimum requirements that the device must have?
                                                </a>
                                                <div className="collapse" id="collapse2">
                                                    <ul>
                                                        <li>Android 6 or higher operating system.</li>
                                                        <li>iOS 11 or higher operating system.</li>
                                                        <li>Internet connection for downloading the app. Once installed, it can be used offline.</li>
                                                        <li>Camera with less than 3 Megapixels, with autofocus.</li>
                                                        <li>Gyroscope (necessary for a better experience of the games <i>Home Sweet Home</i> and <i>The Egg's Journey</i>).</li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="conteudoCollapse">
                                                <a data-toggle="collapse" href="#collapse3" aria-expanded="false" aria-controls="collapseExample">
                                                    3. How do I download <i>Kids' Web</i> Games?
                                                </a>
                                                <div className="collapse pleft" id="collapse3">
                                                    <p>Go to the app store on your device. If you prefer, press the icon above corresponding to the operating system of your device to access it.</p>
                                                </div>
                                            </div>

                                            <div className="conteudoCollapse">
                                                <a data-toggle="collapse" href="#collapse4" aria-expanded="false" aria-controls="collapseExample">
                                                    4. Do I need a registration or some code to use the app?
                                                </a>
                                                <div className="collapse pleft" id="collapse4">
                                                    <p>No registration or access code is required. You just need to have the <b><i>Kids' Web</i></b> series books, because after installing the app you will need to press the "Scan the cover" button and point the camera on your device at the book cover. The game that corresponds to that cover will be automatically activated.</p>
                                                </div>
                                            </div>

                                            <div className="conteudoCollapse">
                                                <a data-toggle="collapse" href="#collapse5" aria-expanded="false" aria-controls="collapseExample">
                                                    5. After installing the app, how do I play the game?
                                                </a>
                                                <div className="collapse pleft" id="collapse5">
                                                    <p>In order to play the games for the first time, they must be activated. To do this:</p>
                                                    <ol>
                                                        <li>Open the app.</li>
                                                        <li>Press the "Scan the cover" button and the app will activate the camera on your device.</li>
                                                        <li>Point the camera at the cover of your book.</li>
                                                        <li>That's it! Your game is activated.</li>
                                                        <li>Press the "Play" button and start playing.</li>
                                                        <li>If you wish, press the question mark button in the upper right corner of the screen to watch a tutorial on how to play.</li>
                                                    </ol>
                                                </div>
                                            </div>

                                            <div className="conteudoCollapse">
                                                <a data-toggle="collapse" href="#collapse6" aria-expanded="false" aria-controls="collapseExample">
                                                    6. How can I play again after exiting the app?
                                                </a>
                                                <div className="collapse pleft" id="collapse6">
                                                    <p>As you have already activated the game, to play again you just need to:</p>
                                                    <ol>
                                                        <li>Open the app.</li>
                                                        <li>Press the "My Games" button.</li>
                                                        <li>Select the game you have already activated and want to play.</li>
                                                        <li>Press the "Play" button to start playing. </li>
                                                    </ol>
                                                </div>
                                            </div>

                                            <div className="conteudoCollapse">
                                                <a data-toggle="collapse" href="#collapse7" aria-expanded="false" aria-controls="collapseExample">
                                                    7. My device is not scanning the cover. What can I do?
                                                </a>
                                                <div className="collapse pleft" id="collapse7">
                                                    <p>The cover images should be read in a well-lit environment. Try scanning the cover again in a better lit location. If the problem persists, contact a technician and check if the camera on your device is experiencing an issue.</p>
                                                </div>
                                            </div>

                                            <div className="conteudoCollapse">
                                                <a data-toggle="collapse" href="#collapse8" aria-expanded="false" aria-controls="collapseExample">
                                                    8. When I press the "Scan the cover" button the device displays an error screen. What should I do?
                                                </a>
                                                <div className="collapse pleft" id="collapse8">
                                                    <p>Close the app and open it again. A message asking for permission to take pictures or record videos should appear. Select the option to allow it. If the problem persists, contact a technician and check if the camera on your device is experiencing an issue.</p>
                                                </div>
                                            </div>

                                            <div className="conteudoCollapse">
                                                <a data-toggle="collapse" href="#collapse9" aria-expanded="false" aria-controls="collapseExample">
                                                    9. Why are there padlocks on some games in the "My games" area?
                                                </a>
                                                <div className="collapse pleft" id="collapse9">
                                                    <p>The padlocks on the games indicate that they have not yet been activated. To activate them, you need to scan the cover of each book.</p>
                                                    <ul>
                                                        <li>Little Finder (<i>Kids' Web</i> 1)</li>
                                                        <li>Magic Animals (<i>Kids' Web</i> 2)</li>
                                                        <li>Home Sweet Home (<i>Kids' Web</i> 3)</li>
                                                        <li>The Egg's Journey (<i>Kids' Web</i> 4)</li>
                                                        <li>World Explorer (<i>Kids' Web</i> 5)</li>
                                                    </ul>
                                                    <p>Once you have the book cover, press the  "Scan the cover" button or the locked game you want to activate in the "My games" area. Then follow the steps indicated in answer 4.</p>
                                                </div>
                                            </div>

                                            <div className="conteudoCollapse">
                                                <a data-toggle="collapse" href="#collapse10" aria-expanded="false" aria-controls="collapseExample">
                                                    10. I don't know how to play. What do I do?
                                                </a>
                                                <div className="collapse pleft" id="collapse10">
                                                    <p>On the start screen of each game, and also during the games, there is a button with a question mark (?) in the upper right corner of the screen. Press this button and watch a tutorial on how to play.</p>
                                                </div>
                                            </div>

                                            <div className="conteudoCollapse">
                                                <a data-toggle="collapse" href="#collapse11" aria-expanded="false" aria-controls="collapseExample">
                                                    11. Do I need the book to play?
                                                </a>
                                                <div className="collapse pleft" id="collapse11">
                                                    <p>You only need the book the first time you play, as each game is activated by the cover image. There is no need to consult your book to be able to play.</p>
                                                </div>
                                            </div>

                                            <div className="conteudoCollapse">
                                                <a data-toggle="collapse" href="#collapse12" aria-expanded="false" aria-controls="collapseExample">
                                                    12. How do I return to the app's home screen when playing?
                                                </a>
                                                <div className="collapse pleft" id="collapse12">
                                                    <p>In every game there is a button with a house icon in the upper left corner of the screen. Press it and return to the app home screen.</p>
                                                </div>
                                            </div>

                                            <div className="conteudoCollapse">
                                                <a data-toggle="collapse" href="#collapse13" aria-expanded="false" aria-controls="collapseExample">
                                                    13. Why can't I see the <i>Home Sweet Home</i> and <i>The Egg's Journey</i> environments by moving my device?
                                                </a>
                                                <div className="collapse pleft" id="collapse13">
                                                    <p>This happens on devices that don't have the gyroscope feature. If this is the case of your device, you can move around the scene by sliding your finger on your device's screen.</p>
                                                </div>
                                            </div>

                                            <div className="conteudoCollapse">
                                                <a data-toggle="collapse" href="#collapse14" aria-expanded="false" aria-controls="collapseExample">
                                                    14. How do I close the app?
                                                </a>
                                                <div className="collapse pleft" id="collapse14">
                                                    <p>Follow these instructions according to your device's operating system:</p>
                                                    <p>Android:</p>
                                                    <ol>
                                                        <li>Access the multitask button.</li>
                                                        <li>Drag the app up or to the right to close it (depending on the default operation to close apps of your Android version).</li>
                                                    </ol>
                                                    <p>iPhone (versions older than X):</p>
                                                    <ol>
                                                        <li>Press the "Home" button twice.</li>
                                                        <li>Drag the app up to close it.</li>
                                                    </ol>
                                                    <p>iPhone X and higher:</p>
                                                    <ol>
                                                        <li>Access the app home screen.</li>
                                                        <li>Slide your finger upwards the middle of the screen.</li>
                                                        <li>Drag the app up to close it.</li>
                                                    </ol>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Footer seta={true} idioma="en"/> 
                        </main>

                    </div>
                </div>
            </div>
        );
  }
}
