import React from 'react'
import ReactDOM from 'react-dom'
import videojs from 'video.js'
import Hosts from '../global/Hosts'
import $ from 'jquery'
class Player extends React.Component {
    
    constructor(){
        super();
        this.state={
            width:0,
            heigth:0
        }
    }

    componentDidMount() {
      var self = this;
        
      this.player = videojs(this.videoNode, this.props.options, function onPlayerReady() {
           
      });
      if(this.props.options.language == 'pt'){
        $('.vjs-big-play-button').prop('title','Reproduzir vídeo');
      }

    }  
    componentDidUpdate(){
      
      if(this.props.options.language == 'pt'){
        $('.vjs-big-play-button').prop('title','Reproduzir vídeo');
      }
    } 
 
    render() {
      console.log(this.props.options)
        return(      
            <video  id='my-video'                     
                    ref={ node => this.videoNode = node } 
                    className={this.props.videoClassName} 
                    width={this.props.options.width} 
                    height = {this.props.options.height}  
                    preload='auto'
                    poster= {Hosts['images']+this.props.options.thumb } 
                    data-setup='{}'>               
            </video>
        )
      }


  }
  


  export default Player;